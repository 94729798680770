export const content = {
    de: `
### Haftung für Inhalte
    
    
Da wir uns als Aufklärungsdienst verstehen sind wir für eigene Inhalte auf diesen Seiten nach den
allgemeinen Gesetzen verantwortlich. Wir sind als Zweitanbieter jedoch nicht verpflichtet,
übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
Davon unberührt bleiben die Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
Informationen nach den allgemeinen Gesetzen. Erst ab dem Zeitpunkt der Kenntnis einer konkreten
Rechtsverletzung ist eine diesbezügliche Haftung möglich. Bei Bekanntwerden von entsprechenden
Rechtsverletzungen werden diese Inhalte selbstverständlich umgehend entfernt.

### Haftung für Links
    
    
Unsere Artikel stützen sich auf andere online Dienste und enthalten Links zu externen Websites
Dritter, auf deren Inhalte wir keinen Einfluss haben. Für diese fremden Inhalte können wir daher
auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist der jeweilige Anbieter oder
Betreiber der Seiten kontinuierlich verantwortlich. Zum Zeitpunkt der Verlinkung wurden die
verlinkten Seiten auf mögliche Rechtsverstöße überprüft. Zum Zeitpunkt der Verlinkung waren keine
Rechtswidrige Inhalte erkennbar.

Ohne konkrete Anhaltspunkte einer Rechtsverletzung ist eine permanente inhaltliche Kontrolle der
verlinkten Seiten allerdings nicht zumutbar. Selbstverständlich werden wir derartige Links umgehend
entfernen sobald uns Rechtsverletzungen bekannt werden.
  `,
    en: `
### Liability for Content

    
As an educational service, we are responsible for our own content on these
pages according to general law. As a secondary provider, however, we are not obliged to monitor
transmitted or stored third-party information or to investigate circumstances that indicate illegal
activity.
This does not affect our obligations to remove or block the use of information in accordance with
general laws. Liability in this respect is only possible from the time of knowledge of a concrete
infringement. Upon becoming aware of such violations, these contents will of course be removed
immediately.

### Liability for Links
    

Our articles are based on other online services and contain links to external websites of third parties,
over whose contents we have no influence. We can therefore not assume any liability for these
external contents. The respective provider or operator of the sites is continuously responsible for the
contents of the linked sites. At the time of linking, the linked pages were checked for possible legal
violations. At the time of linking, no illegal content was identified.
Without concrete evidence of a violation of the law, however, permanent monitoring of the content
of the linked pages is not reasonable. Of course we will remove such links immediately as soon as we
become aware of any infringements.
  `,
};
