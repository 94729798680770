/* eslint-disable multiline-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkDown from 'react-markdown';

import { content } from './ContentLiability.markdown';
import globalComponentMountOperations from '../../../infrastructure/utilities/globalComponentMountOperations';
import PageHeader from '../../atoms/PageHeader';
import Head from '../../atoms/Head';
import RouteGenerator from '../../../infrastructure/Navigation/RouteGenerator';
import { GFBrand } from '../../models/GFBrand';

class ContentLiability extends React.Component
{
    componentDidMount()
    {
        globalComponentMountOperations.handleRouteComponentMount();
    }

    render()
    {
        const { locale } = this.props;

        return (
            <section className="about-us-container">
                <Head
                    locale={this.props.locale}
                    ogImage={RouteGenerator.forPublicUrl('/wahrheit.jpg')}
                    ogTitle={`Glaubensfutter - ${locale === 'de' ? 'Haftungsausschluss' : 'Content Disclaimer'}`}
                    ogDescription={GFBrand.BRAND_DESCRIPTION}
                    ogUrl={RouteGenerator.forAboutUsPage(this.props.pageContext.locale).getFullUrl()}
                />
                <PageHeader
                    title="content_disclaimer"
                    translateTitle={true}
                    showHr={true}
                />
                <div className="article-reader">
                    <ReactMarkDown >
                        {locale === 'de' ? content.de : content.en}
                    </ReactMarkDown>
                </div>
            </section>
        );
    }
}

ContentLiability.propTypes = {
    locale: PropTypes.string,
    pageContext: PropTypes.object,
};

export default ContentLiability;
